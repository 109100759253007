import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faTrash,
  faEdit,
  faArrowUp,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import useDialog from "../../hooks/useDialog";
import ViewTemplateModal from "./view-template";
import EditTemplateModal from "./edit-template";
import ReusableModal from "../ui/reusable-modal";

const DraftTable = ({ draftTemplates, setDraftTemplates }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  const [templateId, setTemplateId] = useState(null);

  const { Dialog: ViewTemplate, setShowDialog: ShowViewTemplate } = useDialog();
  const { Dialog: EditTemplate, setShowDialog: ShowEditTemplate } = useDialog();
  const { Dialog: DeleteTemplate, setShowDialog: ShowDeleteTemplate } =
    useDialog();
  const { Dialog: LocalTestTemplate, setShowDialog: ShowLocalTestTemplate } = 
    useDialog();
  const { Dialog: PromoteTemplate, setShowDialog: ShowPromoteTemplate } =
    useDialog();

  // handle preview template
  const handlePreview = (template) => {
    setSelectedTemplate(template);
    ShowViewTemplate(true);
  };

  //   handle edit content
  const handleEdit = (template) => {
    setSelectedTemplate(template);
    const parsed = JSON.stringify(template, null, 2);
    setEditedContent(parsed);
    ShowEditTemplate(true);
  };

  // open delete
  const openDelete = (id) => {
    setTemplateId(id);
    ShowDeleteTemplate(true);
  };

  // open localtest
  const openLocalTest = (id) => {
    setTemplateId(id);
    ShowLocalTestTemplate(true);
  };

  // open promote
  const openPromote = (template) => {
    setSelectedTemplate(template);
    ShowPromoteTemplate(true);
  };

  //   handle delete template
  const handleDelete = () => {
    axios
      .delete(`${BASE_URL}/${templateId}`)
      .then(() => {
        setDraftTemplates(
          draftTemplates.filter((template) => template.id !== templateId)
        );
        toast.success("Draft template deleted successfully!");
        ShowDeleteTemplate(false); // fix popup issues
      })
      .catch((error) => {
        console.error("Error deleting draft template:", error);
        toast.error("Error deleting draft template. Please try again.");
      });
  };

  // handle run local test
  const handleLocalTest = () => {
    axios
      .get(`${BASE_URL}/id/${templateId}`)
      .then((response) => {
        const templateData = response.data;
        const runtimePayload = JSON.parse(templateData.runtimePayload);
        const jsonData = JSON.stringify(runtimePayload, null, 2);
        const blob = new Blob([jsonData], { type: 'application/json' });
        
        // create a link element to trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        const sanitizedTestName = templateData.testName.replace(/\s+/g, '-');
        link.download = `${sanitizedTestName}.json`;
        link.click();
  
        toast.success("Runtime payload downloaded successfully!");
        ShowLocalTestTemplate(false); // close popup
      })
      .catch((error) => {
        console.error("Error fetching draft template:", error);
        toast.error("Error fetching draft template. Please try again.");
      });
  };

  // Configure and promote template to test
  const handleConfigure = () => {
    const promotedTest = {
      type: 0,
      status: "",
      lastRunTime: null,
      ...selectedTemplate,
    };

    // Remove from draftTemplates and add to tests
    // handleDeleteforConfigure(template.id)
    // setTests([...tests, promotedTest]);

    // Send the new test data to the backend
    axios
      .post(`${BASE_URL}/promote/${promotedTest.id}`)
      .then((response) => {
        toast.success("Template successfully promoted to Test.");
        // setShowConfigureModal(false);

        // Update the tests state by adding the promoted test
        // setTests((prevTests) => [...prevTests, promotedTest]);

        // Remove the promoted template from draftTemplates
        setDraftTemplates((prevDraftTemplates) =>
          prevDraftTemplates.filter((t) => t.id !== selectedTemplate.id)
        );
        ShowPromoteTemplate(false);
      })
      .catch((error) => {
        toast.error("Error promoting template:", error);
        alert("Error promoting template. Please try again.");
      });
  };

  return (
    <div className="">
      {/* table */}
      <div className="flex flex-col h-[60dvh] mt-4 overflow-y-auto">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full">
            <div className="overflow-hidden">
              <table className="min-w-full text-center text-sm font-light">
                <thead className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                  <tr className="bg-[#122E57]">
                    <th className="bg-[#122E57] text-white font-[600] px-2 py-2">
                      Name
                    </th>
                    <th className="bg-[#122E57] text-white font-[600] px-2 py-2">
                      Description
                    </th>
                    <th className="bg-[#122E57] text-white font-[600] px-2 py-2">
                      Frequency
                    </th>
                    <th className="bg-[#122E57] text-white font-[600] px-2 py-2">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {draftTemplates.map((template) => (
                    <tr key={template.id}>
                      <td className="whitespace-nowrap bg-white border-r border-b bg-opacity-30  px-3 py-3">
                        {template.testName}
                      </td>
                      <td className="whitespace-nowrap bg-white border-r border-b bg-opacity-30  px-3 py-3">
                        {template.testDescription}
                      </td>
                      <td className="whitespace-nowrap bg-white border-r border-b bg-opacity-30  px-3 py-3">
                        {template.frequency}
                      </td>
                      <td className="whitespace-nowrap bg-white border-r border-b bg-opacity-30  px-3 py-3">
                        <button
                          onClick={() => handlePreview(template)}
                          className="btn-int p-1 px-2"
                          title="Preview"
                        >
                          <FontAwesomeIcon icon={faEye} size={"xs"} />
                        </button>
                        <button
                          onClick={() => handleEdit(template)}
                          className="btn-int p-1 px-2"
                          title="Edit"
                        >
                          <FontAwesomeIcon icon={faEdit} size={"xs"} />
                        </button>
                        <button
                          onClick={() => openDelete(template.id)}
                          className="btn-int p-1 px-2"
                          title="Delete"
                        >
                          <FontAwesomeIcon icon={faTrash} size={"xs"} />
                        </button>
                        <button
                          onClick={() => openLocalTest(template.id)}
                          className="btn-int p-1 px-2"
                          title="Run Locally"
                        >
                          <FontAwesomeIcon icon={faPlay} size={"xs"} />
                        </button>
                        <button
                          onClick={() => openPromote(template)}
                          className="btn-int p-1 px-2"
                          title="Promote"
                        >
                          <FontAwesomeIcon icon={faArrowUp} size={"xs"} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ViewTemplate title={"View Template"} size={"lg"}>
          <ViewTemplateModal
            template={selectedTemplate}
            close={() => ShowViewTemplate(false)}
          />
        </ViewTemplate>
        <EditTemplate title={"Edit Template"} size={"lg"}>
          <EditTemplateModal
            editedContent={editedContent}
            template={selectedTemplate}
            close={() => ShowEditTemplate(false)}
            draftTemplates={draftTemplates}
            setDraftTemplates={setDraftTemplates}
          />
        </EditTemplate>
        <DeleteTemplate title={""} size={"sm"}>
          <ReusableModal
            title={"Are you sure you want to delete this template?"}
            type={"delete"}
            actionTitle={"Yes, Delete"}
            closeModal={() => ShowDeleteTemplate(false)}
            cancelTitle={"No, Close"}
            action={handleDelete}
          />
        </DeleteTemplate>
        <LocalTestTemplate title={"Run Local Test"} size={"sm"}>
          <ReusableModal
            title={
              <>
                Please update your Chrome browser:{" "}
                <br />
                <a
                  href="https://www.google.com/chrome/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  Download Latest Google Chrome
                </a>
                <br />
                <br />
                Please download the local test package:{" "}
                <br />
                <a
                  href="/selenium-local-test.zip"
                  download="selenium-local-test.zip"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  Local Test Package
                </a>
              </>
            }
            type={"warning"}
            actionTitle={"Continue to Download Draft Template"}
            closeModal={() => ShowLocalTestTemplate(false)}
            cancelTitle={"No, Close"}
            action={handleLocalTest}
          />
        </LocalTestTemplate>
        <PromoteTemplate title={""} size={"sm"}>
          <ReusableModal
            title={"Are you sure you want to promote this template from draft?"}
            type={"warning"}
            actionTitle={"Yes, Promote"}
            closeModal={() => ShowPromoteTemplate(false)}
            cancelTitle={"No, Close"}
            action={handleConfigure}
          />
        </PromoteTemplate>
      </div>
    </div>
  );
};

export default DraftTable;
