import React from "react";
import TestTable from "../components/manage-test/test-table";
import { DASHBOARD_URL_WHOLE_TEMPLATE, OPENSEARCH_URL } from "../services/constants";

const TestPage = () => {
  return (
    <div className="h-[calc(100%-1.75rem)]">
      <div className="place-center h-full">
        <div className="relative w-[90dvw] h-[80dvh] bg-gray-50 bg-opacity-20 shadow-lg p-3 lg:px-6 rounded-xl">
          <div className="relative z-10">
            <div className="flex justify-between items-center">
              <p className="syne font-[700] text-xl text-white">
                Manage Monitoring Tests
              </p>
              <div className="flex justify-end mb-4">
                <button
                  className="btn-int h-8 p-1 px-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                  onClick={() => window.open(DASHBOARD_URL_WHOLE_TEMPLATE.replace("{base_url}", OPENSEARCH_URL), "_blank")}
                >
                  <p className="syne font-[700] text-m text-white"> 
                     Dashboard
                  </p>
                </button>
              </div>
            </div>
            <TestTable />
          </div>
          <div className="absolute top-0 left-0 w-full h-full backdrop-blur rounded-xl"></div>
        </div>
      </div>
    </div>
  );
};

export default TestPage;
